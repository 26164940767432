<template>
    <div>
        <div class="top-text" v-show="!loadingPage" style="pointer-events: none">
            <div class="title">Sala de visita virtual</div>
            <span>Início: {{ agendamento.dataAgendamento }}</span>
        </div>
        <div class="backdrop" v-show="loadingPage">
            <div class="fas fa-dollar-sign spin"></div>
            <div class="loading-text">
                Carregando informações da chamada de vídeo...
            </div>
        </div>
        <iframe v-if="agendamento" :src="agendamento.link" allow="camera; microphone; fullscreen; speaker; display-capture"
            style="min-height: 100vh; width: 100%"></iframe>
    </div>
</template>


<style lang="sass" scoped>

.top-text 
    position: absolute
    top: 0
    left: 0
    right: 0
    text-align: center
    z-index: 100
    color: #fff
    margin-top: 8px
    padding-left: 10px
    padding-rigth: 10px
    font-size: 0.75rem
    font-weight: 400

.title
    font-size: 1rem
    margin-bottom: -6px
    font-weight: bold

.backdrop 
    position: absolute
    width: 100%
    height: 100%
    --bg-opacity: 1
    background-color: #fff
    background-color: rgba(255, 255, 255, var(--bg-opacity))
    text-align: center
    font-size: 2.25rem
    --text-opacity: 1
    color: #02B26E
    color: rgba(2, 178, 110, var(--text-opacity))
    z-index: 60
    opacity: 0.9
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.loading-text 
    margin-top: 20px
    font-size: 1.5rem
.spin
  animation: rotatey 2s linear infinite

@keyframes rotatey
  from
    transform: rotateY(0deg)
  to
    transform: rotateY(360deg)
</style>

<script>
import Business from '@/services/Business/Dashboard'


export default {
    props: [],
    components: {
    },
    data() {
        return {
            agendamento: null,
            loadingPage: true
        }
    },
    async created() {
        const response = await Business.linkWhereBy(this.$route.params.uId)
        this.agendamento = response.data.data;
        this.loadingPage = false
    },
    mounted() {
        if (this.link)
            this.loadingPage = false
    }
}

</script>
